<template>
  <div class="company-settings" v-loading="loading">

    <library-scales-units-modal>
      <el-button
        slot="reference"
        type="primary"
        size="small"
      >
        Редактирование единиц измерения
      </el-button>
    </library-scales-units-modal>

  </div>
</template>

<script>

import {companySettings} from "@/mixins/companySettings";
import LibraryScalesUnitsModal from "@/components/library/LibraryScalesUnitsModal.vue";

export default {
  name: "company-settings",
  components: {LibraryScalesUnitsModal},
  mixins: [companySettings],


  props: {},

  watch: {},
  data() {
    return {
    }
  },
  mounted() {
  },
  methods: {}
}
</script>


<style lang="scss">

</style>